body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
  align-items: center;
  background: white;
  border-radius: 10px;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  min-height: 200px;
  width: 300px;
}

.cardGrid {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, 300px);
}